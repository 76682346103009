.app-modal {

  .modal-body {
    max-height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;

    &.overflow-visible {
      max-height: unset;
    }
  }

  @include media-breakpoint-down(md) {
    .modal-body {
      max-height: unset;
    }
  }
}

.modal.show .modal-dialog {
  transform: none;
  animation: showModal .5s forwards !important;
}

@keyframes showModal {
  0% {
    opacity: 0;
    transform: translate(0, -50%);
  }
  100% {
    transform: none;
  }
}

@include media-breakpoint-up(lg) {
  .modal-xl { max-width: $modal-xl; }
}

.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 3000 !important;
  }
}
