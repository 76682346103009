.uppercase {
  text-transform: uppercase;
}

.error {
  color: $form-feedback-invalid-color;
  font-size: 80%;
}

.cursor-pointer {
  cursor: pointer;
}

.divider-dashed {
  border-top-style: dashed;
  margin: 1rem 0;
}

.overflow-auto {
  overflow: auto;
}

.border-radius-0 {
  border-radius: 0 !important;
}
