.express-questions {
  &-msg-body {
    height: calc(100vh - 250px);
    overflow: auto;
  }
}

.chat {
  &-messages {
    @include media-breakpoint-up(md) {
        overflow-y: auto;
        height: calc(100vh - 125px);
    }

    .card-body {
      overflow: auto;
      display: flex;
      flex-direction: column-reverse;
    }

    .card-footer {
      background: white;
      height: 78px;
      padding-top: 0;
      padding-bottom: 0;

      .chat-send-message {
        width: 100%;
        height: 100%;
        resize: none;
        border: 0;
        outline: none;
        font-size: 14px;

        &::placeholder {
          color: $gray-3;
        }
      }
    }

    &-message {

      &-container {
        max-width: 60%;
        min-width: 20%;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }

      &-right {
        display: flex;
        justify-content: flex-end;

        .chat-messages-message-content {
          background-color: $green-1;
          color: $white;
        }

        @include media-breakpoint-up(md) {
          &-margin {
            margin-right: calc(48px + 0.5rem);
          }
        }
      }

      &-center {
        display: flex;
        justify-content: center;
        text-align: center;

        .chat-messages-message-content {
          background-color: $gray-5;
          color: $white;
        }
      }

      &-left {
        display: flex;

        .chat-messages-message-content {
          background-color: $black-1;
          color: white;

          .chat-messages-message-time {
            color: #888888;
          }
        }

        @include media-breakpoint-up(md) {
          &-margin {
            margin-left: calc(48px + 0.5rem);
          }
        }
      }

      &-date {
        font-size: 13px;
        color: #747474;
        text-align: center;
        margin-bottom: .8rem;
        margin-top: .8rem;
      }

      &-username {
        font-weight: 500;
        font-size: 13px;
        color: #747474;
      }

      &-content {
        margin: 0;
        border-radius: 5px;
        font-weight: 300;
        font-size: 13px;
        padding: .4rem;

        .chat-messages-message-time {
          text-align: right;
          font-size: 11px;
        }
      }

      &-text {
        word-break: break-word;
      }

      &-image img {
        padding-top: 0.6rem;
        max-width: 100%;
        max-height: 200px;
      }
    }

    &-load {
      display: flex;
      justify-content: center;
    }
  }
}
