.avatar-xxl, .avatar-xxl img {
  @include avatar(100px);
}

.avatar-xsm, .avatar-xsm img {
  @include avatar(25px);
}

.avatar {
  background-color: $gray-200;
}
