.page-item {

  .page-link,
  span {
    border-radius: $pagination-border-radius !important;
    width: $pagination-width;
    height: $pagination-height;
  }
}

.pagination-select {
  flex: 1;
  max-width: 180px;
}
