.btn-link, .btn-link:hover, .btn-link:active {
  color: $primary !important;
  font-weight: normal;
  box-shadow: none !important;
}

.btn-outline-link, .btn-outline-link:hover, .btn-outline-link:active {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0;
  color: $primary !important;
  font-weight: normal;
  transform: none;
}

.btn-outline-link-danger, .btn-outline-link-danger:hover, .btn-outline-link-danger:active {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0;
  color: $danger !important;
  font-weight: normal;
  transform: none;
}

.btn-app-sm {
  padding: 0.25rem 1rem;
}
