.daterange-picker {
  position: absolute;
  z-index: 1000;
  background-color: white;
  text-align: initial;
  min-width: 630px;
  margin-top: 5px;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .DateRangePicker__CalendarSelection {
    background-color: $primary;

    &--is-pending {
      background-color: rgba($primary, .8);
    }
  }

  .DateRangePicker__CalendarHighlight {
    border-color: $primary;
  }
}

.btn-daterange {
  background-color: $white !important;
  box-shadow: none !important;
  border: 0;
  font-weight: normal;

  &:hover {
    transform: none;
  }

  i {
    vertical-align: middle;
    margin-left: 0.5rem;
    color: $gray-400;
  }
}
