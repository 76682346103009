.bg-app-dark, .bg-app-dark:hover, .bg-app-dark:active {
  background-color: $black-1;
}

.bg-gray-3 {
  background-color: $gray-3;
}

.bg-gray-4 {
  background-color: $gray-4;
}

.bg-gray-7 {
  background-color: $gray-7;
}

.no-main-color {
  color: unset;
}

.text-app-white {
  color: $white;

  &:hover {
    color: $white !important;
  }
}

.text-gray-2 {
  color: $gray-2;

  &:hover {
    color: $gray-2;
  }
}

.text-gray-3 {
  color: $gray-3;

  &:hover {
    color: $gray-3;
  }
}

.text-gray-800 {
  color: $gray-800;

  &:hover {
    color: $gray-800;
  }
}
