body {
  overflow-y: hidden;
  height: 100%;
  color: $black-1;
}

.main-content-auth {
  margin-top: $navbar-nav-height;
}

.main-content-wrapper {
  margin-top: $navbar-nav-height;
  overflow-y: auto;
  height: calc(100vh - #{$navbar-nav-height});

  @include media-breakpoint-up(sm) {
    margin-top: $navbar-nav-height;
  }
}

p {
  font-weight: normal;
}
