.form-control-label {
  font-weight: $font-weight-normal;
  margin-bottom: 0;
  color: $black-1;
}

.form-group.is-invalid .invalid-feedback {
  display: block;
}

.focused .is-invalid {
  border-color: $form-feedback-invalid-color !important;
}

.form-control.form-control-xsm {
  height: $input-height-xsm;
  padding: $input-padding-y-xsm $input-padding-x-xsm;
  font-size: $font-size-xsm;
  line-height: $input-line-height-xsm;
  @include border-radius($input-border-radius-sm);
}


.input-text-uppercase {
  input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }
}

.wysiwyg-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.15);

  .DraftEditor-editorContainer {
    padding: 0 10px;
    min-height: 200px;
  }
}
