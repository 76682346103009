.table {
  thead tr th:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  thead tr th:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  th {
    border: 0 !important;
    font-weight: normal;
    text-transform: capitalize !important;
    border-radius: 0 !important;
  }

  tbody td {
    color: $table-body-color;
    border-top: 0 !important;
    border-bottom: $table-border-width solid $table-border-color !important;
    font-size: 0.875rem;
  }

  tbody tr:hover {
    background-color: rgba($gray-300, .2);
  }

  td {
    white-space: normal;
    vertical-align: middle;
  }
}
