.text-xsm {
  font-size: $font-size-xsm;
}

.text-2xsm {
  font-size: $font-size-2xsm;
}

.text-md {
  font-size: $font-size-base;
}

.text-transform-none {
  text-transform: none !important;
}
