.rna-wrapper {
  .alert-outline-primary {
    background-color: $gray-100 !important;
    color: $primary;

    .close > span:not(.sr-only) {
      color: $primary;
    }
  }

  .alert {
    border-radius: 0 !important;
  }

  > div > div {
    z-index: 10000 !important;
  }

  .alert [data-notify="icon"] {
    font-size: .9rem;
    margin-right: 1rem;
  }

  .alert-text {
    line-height: 1;
  }
}

.sweetalert-confirmation {
  border-radius: 0 !important;
}
