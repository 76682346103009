.dropzone {
  background-color: $dropzone-container-bg;
  border: 1px dashed $dropzone-container-border;
  border-radius: $dropzone-container-border-radius;
  text-align: center;
  color: $dropzone-color;
  cursor: pointer;
  display: flex;
  justify-content: center;

  .dz-preview, .dz-preview-cover {
    position: relative;
  }

  .dz-preview-img {
    border-radius: 0;
  }

  .dz-message {
    border: 0;
    padding: 2rem 1rem;
    margin: 0;
    overflow: auto;
    z-index: unset;
    color: $dropzone-color;
    background-color: $dropzone-container-bg;
  }
}
