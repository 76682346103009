.navbar-top.main-navbar {
  height: $navbar-nav-height;
  padding: 0;
  background: white;
  justify-content: space-between;

  .nav-link {
    cursor: pointer;
    color: $black-1 !important;
  }

  .navbar-dropdown {
    .dropdown-item {
      font-size: $font-size-xsm;
    }

    .dropdown-title {
      font-size: $font-size-xsm;
      padding: 0.5rem;
    }

    .dropdown-divider {
      border-top-style: dotted;
      border-top-color: $gray-7;
      margin: 0.3rem 0.5rem;
    }

    .dropdown-item:hover {
      background-color: $gray-1;
    }
  }
}

.g-sidenav-pinned {
  .sidenav {
    @include media-breakpoint-up(lg) {
      &.fixed-left ~ .navbar-top.fixed-top {
        left: $navbar-vertical-open-width;
        @include transition($transition-base);
      }
      &.fixed-left ~ .main-content {
        margin-left: $navbar-vertical-open-width;
        @include transition($transition-base);
      }
    }
  }
}

.g-sidenav-show {
  .sidenav {
    @include media-breakpoint-up(lg) {
      max-width: $navbar-vertical-open-width !important;
    }
  }
}

.sidenav {
  .navbar-nav {
    .nav-link {
      font-weight: normal;
      color: $black-1;
    }

    .nav .nav-link {
      padding-left: 2.5rem !important;
    }

    .active > .nav-link, .nav-link.active {
      color: $green-1 !important;
    }
  }

  @include media-breakpoint-up(lg) {
    transform: unset;

    .sidenav-toggler {
      padding: $navbar-vertical-padding-x;
    }

    &.fixed-left ~ .navbar-top.fixed-top {
      left: $navbar-vertical-width;
      @include transition($transition-base);
    }
    &.fixed-left ~ .main-content {
      margin-left: $navbar-vertical-width;
      @include transition($transition-base);
    }
  }
}
