.card-app-default {
  background-color: $white;
  box-shadow: none;
  border: 1px solid $gray-7;
  border-radius: 0;

  .card-body {
    background-color: $white;
  }
}

.card-sm {
  .card-header, .card-body {
    padding: $card-spacer-y-sm $card-spacer-x-sm;
  }
}
